module.exports={
  "name": "pixi.js",
  "version": "3.0.7",
  "description": "Pixi.js is a fast lightweight 2D library that works across all devices.",
  "author": "Mat Groves",
  "contributors": [
    "Chad Engler <chad@pantherdev.com>",
    "Richard Davey <rdavey@gmail.com>"
  ],
  "main": "./src/index.js",
  "homepage": "http://goodboydigital.com/",
  "bugs": "https://github.com/GoodBoyDigital/pixi.js/issues",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "https://github.com/GoodBoyDigital/pixi.js.git"
  },
  "scripts": {
    "start": "gulp && gulp watch",
    "test": "gulp && testem ci",
    "build": "gulp",
    "docs": "jsdoc -c ./gulp/util/jsdoc.conf.json -R README.md"
  },
  "files": [
    "bin/",
    "src/"
  ],
  "dependencies": {
    "async": "^0.9.0",
    "brfs": "^1.4.0",
    "earcut": "^2.0.1",
    "eventemitter3": "^1.1.0",
    "object-assign": "^2.0.0",
    "resource-loader": "^1.6.1"
  },
  "devDependencies": {
    "browserify": "^10.2.3",
    "chai": "^3.0.0",
    "del": "^1.2.0",
    "gulp": "^3.9.0",
    "gulp-cached": "^1.1.0",
    "gulp-concat": "^2.5.2",
    "gulp-debug": "^2.0.1",
    "gulp-jshint": "^1.11.0",
    "gulp-mirror": "^0.4.0",
    "gulp-plumber": "^1.0.1",
    "gulp-rename": "^1.2.2",
    "gulp-sourcemaps": "^1.5.2",
    "gulp-uglify": "^1.2.0",
    "gulp-util": "^3.0.5",
    "jaguarjs-jsdoc": "git+https://github.com/davidshimjs/jaguarjs-jsdoc.git",
    "jsdoc": "^3.3.0",
    "jshint-summary": "^0.4.0",
    "minimist": "^1.1.1",
    "mocha": "^2.2.5",
    "require-dir": "^0.3.0",
    "run-sequence": "^1.1.0",
    "testem": "^0.8.3",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "^3.2.1"
  },
  "browserify": {
    "transform": [
      "brfs"
    ]
  }
}
