var core = require('../../core');
// @see https://github.com/substack/brfs/issues/25


/**
 * This applies a sepia effect to your Display Objects.
 *
 * @class
 * @extends PIXI.AbstractFilter
 * @memberof PIXI.filters
 */
function SepiaFilter()
{
    core.AbstractFilter.call(this,
        // vertex shader
        null,
        // fragment shader
        "precision mediump float;\n\nvarying vec2 vTextureCoord;\n\nuniform sampler2D uSampler;\nuniform float sepia;\n\nconst mat3 sepiaMatrix = mat3(0.3588, 0.7044, 0.1368, 0.2990, 0.5870, 0.1140, 0.2392, 0.4696, 0.0912);\n\nvoid main(void)\n{\n   gl_FragColor = texture2D(uSampler, vTextureCoord);\n   gl_FragColor.rgb = mix( gl_FragColor.rgb, gl_FragColor.rgb * sepiaMatrix, sepia);\n}\n",
        // custom uniforms
        {
            sepia: { type: '1f', value: 1 }
        }
    );
}

SepiaFilter.prototype = Object.create(core.AbstractFilter.prototype);
SepiaFilter.prototype.constructor = SepiaFilter;
module.exports = SepiaFilter;

Object.defineProperties(SepiaFilter.prototype, {
    /**
     * The strength of the sepia. `1` will apply the full sepia effect, and
     * `0` will make the object its normal color.
     *
     * @member {number}
     * @memberof SepiaFilter#
     */
    sepia: {
        get: function ()
        {
            return this.uniforms.sepia.value;
        },
        set: function (value)
        {
            this.uniforms.sepia.value = value;
        }
    }
});
